import React, {ReactNode} from 'react';

import styles from './Slider.module.css';

interface SliderProps {
  items: ReactNode[];
}

export default function Slider({items}: SliderProps) {
  return (
    <div className={styles['container']}>
      <ul className={styles['list']}>
        {items.map((item, i) => (
          <li className={styles['item']} key={i}>
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};
