import React from 'react';
import Slider from '@components/Slider/Slider';
import {DishSelector} from '@features/Content';

import styles from './Order.module.css';

const steps = [
  'Салат',
  'Первое',
  'Второе',  
  'Напиток',
];

export default function Order() { 
  return (
    <div>
      <div className={styles['header']}>
        {steps.map((step, i) => (
          <span key={i}>{step}</span>
        ))}
      </div>
      <DishSelector caption="Первое" />
    </div>
  );
};
