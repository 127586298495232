import React from 'react';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {Root} from './routes';

export default function Application() {
  return (    
    <BrowserRouter>
      <Root />
    </BrowserRouter>    
  );
};
